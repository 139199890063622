export function isDateInThisWeek(dateString: string): boolean {
	const today = new Date();
	const dayOfWeek = today.getUTCDay(); // Usar getUTCDay() para obtener la hora UTC
	const differenceToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
	const firstDayOfWeek = new Date(Date.UTC(today.getUTCFullYear(),
		today.getUTCMonth(), today.getUTCDate() - differenceToMonday));
	firstDayOfWeek.setUTCHours(0, 0, 0, 0);
	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setUTCDate(firstDayOfWeek.getUTCDate() + 6);
	lastDayOfWeek.setUTCHours(23, 59, 59, 999);
	const dateToCheck = CreateDateFromNoticeDate(dateString)
	return dateToCheck >= firstDayOfWeek && dateToCheck <= lastDayOfWeek && !AreTwoDatesTheSameDay(dateToCheck, today);
}

export function isDateInThisWeekWithDate(dateToCheck: Date): boolean {
	const today = new Date();
	const dayOfWeek = today.getUTCDay(); // Usar getUTCDay() para obtener la hora UTC
	const differenceToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
	const firstDayOfWeek = new Date(Date.UTC(today.getUTCFullYear(),
		today.getUTCMonth(), today.getUTCDate() - differenceToMonday));
	firstDayOfWeek.setUTCHours(0, 0, 0, 0);
	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setUTCDate(firstDayOfWeek.getUTCDate() + 6);
	lastDayOfWeek.setUTCHours(23, 59, 59, 999);
	return dateToCheck >= firstDayOfWeek && dateToCheck <= lastDayOfWeek && !AreTwoDatesTheSameDay(dateToCheck, today);
}

export function AreTwoDatesTheSameDay(date1: Date, date2: Date): boolean {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getUTCDate() === date2.getUTCDate()
	);
}

export function CreateDateFromNoticeDate(dateString: string): Date {
	if (!dateString) {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		return new Date(year, month, day, 0, 0, 0);
	}
	if (dateString.includes("/")) /* Android Comment */ {
		return HandleDateFromAndroid(dateString) 
	}
	if (dateString.length === 19) /* Iphone Comment */ {
		return HandleDateFromIphone(dateString)
	}
	/* Web Comment */
	/* IMPORTANT: NOTICE TS WILL ALWAYS ENTER THE FOLLOWING FUNCTION */
	return HandleDateFromWeb(dateString)
}

export function HandleDateFromWeb(dateAsString: string) : Date {
	const normalDate = dateAsString.split(" ")[0]
	const hoursDate = dateAsString.split(" ")[1]
	const hoursFixed = hoursDate.split(".")[0]
	const [year, month, day] = normalDate.split('-').map(Number);
	const [hour, minute, second] = hoursFixed.split(":").map(Number)
	return new Date(year, month - 1, day, hour, minute, second)
}

export function HandleDateFromAndroid(dateAsString: string) : Date {
	const normalDate = dateAsString.split(", ")[0]
	const [month, day, year] = normalDate.split('/').map(Number);
	const hoursDate = dateAsString.split(", ")[1]
	const cleanHoursDate = hoursDate.replace(/\s+/g, " ");
	const hoursDateSplited = cleanHoursDate.split(" ");
	const [hour, minute, second] = hoursDateSplited[0].split(":").map(Number)
	if (hoursDateSplited[1].includes("PM")) {
		return new Date(year, month - 1, day, hour + 12, minute, second)
	}
	return new Date(year, month - 1, day, hour, minute, second)
}

export function HandleDateFromIphone(dateAsString: string) : Date {
	const normalDate = dateAsString.split(" ")[0]
	const hoursDate = dateAsString.split(" ")[1]
	const [day, month, year] = normalDate.split('-').map(Number);
	const [hour, minute, second] = hoursDate.split(":").map(Number)
	return new Date(year, month - 1, day, hour, minute, second)
}


export function FormatDate(currentDate: Date): string {

	// NOTE: In case of backend date format change, update this code.

	const formattedDate = currentDate.getFullYear() + "-" +
		String(currentDate.getMonth() + 1).padStart(2, '0') + "-" +
		String(currentDate.getDate()).padStart(2, '0') + " " +
		String(currentDate.getHours()).padStart(2, '0') + ":" +
		String(currentDate.getMinutes()).padStart(2, '0') + ":" +
		String(currentDate.getSeconds()).padStart(2, '0') + "." +
		String(currentDate.getMilliseconds()).padStart(3, '0');
	return formattedDate;
}

export function GetDayFromNow(dateChanger: number): string {
	const currentDate = new Date()
	currentDate.setDate(currentDate.getDate() + dateChanger)
	return FormatDate(currentDate)
}