import React, { useState } from "react";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { HandleImageInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers";
import { FetchPutFunction } from "../../../Helpers/FetchHelpers";
import defaultImage from '../../../Assets/BasicUserIcon.png'
import '../SharedComponents.css'



function HandleImage({ userToShow }: HandleImageInterface) {

	const { schoolOfUser, user, setUser } = useGeneralContext()

	const [newPicture, setNewPicture] = useState<File | undefined>(undefined)
	const [picturePreview, setPicturePreview] = useState<string | undefined>(undefined);
	const [isEditingPicture, setIsEditingPicture] = useState<boolean>(false)

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			setNewPicture(file);
			setPicturePreview(objectUrl);
			setIsEditingPicture(false)
		}
	};

	const handleCancel = () => {
		setIsEditingPicture(false)
		setPicturePreview(undefined)
		setNewPicture(undefined)
	}

	const handleSave = async () => {
		if (!schoolOfUser) {
			return
		}
		if (!newPicture || !picturePreview) {
			alert('Debe seleccionar una imagen valida')
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/users/${userToShow.SK}?school_id=${schoolOfUser.SK}`
			const files = await PrepareFileToSend(newPicture)
			const body = {
				'attrs' : { email : userToShow.SK},
				'files' : files
			}
			await FetchPutFunction(url, body)
			alert('Imagen actualizada')
			updateUserContext(picturePreview)
		} catch (error) {
			alert('Hubo un problema al actualizar su imagen')
		}
	}

	const updateUserContext = ( pictureURL : string) => {
		if (!user) {
			return
		}
		const updatedUser = { ...user, profile_image: pictureURL}
		setUser(updatedUser)
	}

	return (
		<div className="ViewUserPicture">
			{isEditingPicture ? (
				<>
					<input
						type="file"
						accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.HEIF"
						onChange={handleFileChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</>
			) : (
				newPicture ? (
					<>
						<img src={picturePreview} alt="Logo" />
					</>
				) : (
					userToShow.profile_image.trim() === '' ? (
						<img src={defaultImage} alt="Logo" />
					) : (
						<img src={userToShow.profile_image} alt="Logo" />
					)
				)
			)}
			<div className="ViewUserPictureButtons">
				<button type='button' onClick={handleSave} 
					hidden={isEditingPicture || !picturePreview}>
					Guardar
				</button>
				<button type='button' onClick={() => setIsEditingPicture(true)} hidden={isEditingPicture}>
					Editar imagen
				</button>
				<button type='button' onClick={handleCancel} hidden={!picturePreview}>
					Cancelar
				</button>
				
			</div>
		</div>
	)
}

export default HandleImage