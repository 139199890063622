import React, { useEffect, useRef, useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { ViewCommentsOfNoticeInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { CommentInterface, GroupedCommentInterface, GroupInterface, NoticeInterface, SingularCommentSummary,
	StudentInterface } from "../../../Interfaces/Entities"
import { FormatCommentsForChat, FormatCommentsForNewComment, FormatNewCommentForTagging, 
	ReverseFormatNewCommentForTagging } from "../../../Helpers/FormatHelpers"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"
import BasicUserIcon from '../../../Assets/BasicUserIcon.png'
import ClipIcon from '../../../Assets/ClipIcon.png'
import '../SharedComponents.css'
import { FilterStudentsForSearch } from "../../../Helpers/FilterFuntions"



function ViewCommentsOfNotice({ }: ViewCommentsOfNoticeInterface) {

	const { user, schoolOfUser, groupsOfUserAsGroups } = useGeneralContext()
	const { allNotices, setAllNotices, setNoticeToSee, noticeToSee } = useUserModalContext()

	const [newComment, setNewComment] = useState<string>('')
	const [groupOfNotice, setGroupOfNotice] = useState<GroupInterface | undefined>(groupsOfUserAsGroups.find(
		group => group.SK === noticeToSee.groups[0].id) ?? undefined)
	const [studentsOfGroup, setStudentsOfGroup] = useState<StudentInterface[]>(groupOfNotice ? groupOfNotice.students : [])

	const fastComments = ['Voy ahora', 'No puedo ir', 'Voy en breve']
	const notFastComments = noticeToSee.comments.filter((comment: CommentInterface) =>
		!fastComments.includes(comment.comment)
	)
	const groupedComments: GroupedCommentInterface[] = FormatCommentsForChat(notFastComments)
	const noCommentMessage = `Este ${noticeToSee.type === 'General' ? 'boletin' : 'aviso'} no tiene comentarios`

	const [fileToUpload, setFileToUpload] = useState<File[]>([])
	const [fileName, setFileName] = useState<string | null>(null);

	const [isTagging, setIsTagging] = useState<boolean>(false)
	const [tagText, setTagText] = useState<string>('')
	const [taggedStudents, setTaggedStudents] = useState<StudentInterface[]>([])
	const [taggedText, setTaggetText] = useState<{ start: number, end: number, name: string }[]>([])
	const inputStudentSearchRef = useRef<HTMLInputElement>(null);
	const searchStudentResultsRef = useRef<HTMLDivElement>(null);
	const [filteredStudents, setFilteredStudents] = useState<StudentInterface[]>([]);
	const [popupStyle, setPopupStyle] = useState<{ left: number; bottom: number; width: number } | null>(null);

	const createComment = async () => {
		if (!user || !schoolOfUser) {
			return
		}
		if (fileToUpload.length === 0 && newComment.trim() === '') {
			alert('No puede crear un comentario vacio')
			return
		}
		try {
			const commentToCreate: CommentInterface = {
				comment: newComment,
				user_id: user.SK,
				user_name: user.name
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/comments?`
			const previusComments = FormatCommentsForNewComment(noticeToSee.comments, schoolOfUser.SK)
			const body = {
				SK: noticeToSee.SK,
				school_id: schoolOfUser.SK,
				attrs: {
					comments: [...previusComments, commentToCreate]
				},
				notify: noticeToSee.notify ? noticeToSee.notify : false,
				notify_attrs: {
					type: noticeToSee.type,
					groups: noticeToSee.groups,
					user: user.name,
					description: noticeToSee.description,
				}
			}
			if (fileToUpload.length > 0) {
				const filePromises = fileToUpload.map(file => PrepareFileToSend(file));
				const fileData = await Promise.all(filePromises);
				body['comment_file'] = fileData
			}
			await FetchPostFunction(url, body)
			updateNoticesForComment(commentToCreate)
			alert("Comentario creado correctamente")
			setNewComment('')
			setFileToUpload([])
		} catch (error) {
			alert('Hubo un problema al crear un comentario rápido')
		}
	}

	const updateNoticesForComment = (newComment: CommentInterface) => {
		const updatedNotice = { ...noticeToSee, comments: [...noticeToSee.comments, newComment] }
		const updatedNotices = allNotices.map((notice: NoticeInterface) =>
			notice.SK === noticeToSee.SK ? updatedNotice : notice
		)
		setNoticeToSee(updatedNotice)
		setAllNotices(updatedNotices)
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files || []);
		setFileToUpload(files);
		if (files && files.length > 0) {
			setFileName(files[0].name);
		}
	};

	const handleCommentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newCommentValue = e.target.value;
		const mappedStudents = taggedStudents.reduce((acc, student) => {
			acc[student.rut] = student.name;
			return acc;
		}, {} as Record<string, string>);
		const oldText = ReverseFormatNewCommentForTagging(newCommentValue, mappedStudents)
		const originalValue = newComment
		if (originalValue.length > oldText.length) {
			handleDeleteNewComment(oldText, originalValue)
		} else {
			handleAddNewCommentValue(oldText)
		}
	}


	const handleDeleteNewComment = (newCommentValue: string, originalComment: string) => {
		if (!newCommentValue.trim()) {
			setIsTagging(false);
			setTagText('');
			setTaggedStudents([])
			setTaggetText([])
			setNewComment('')
			return;
		}
		const hasTag = newCommentValue.includes('@');
		if (!hasTag) {
			setNewComment(newCommentValue);
			return
		}
		const positionToDelete = getPositionToDelete(newCommentValue, originalComment)
		const textAfterDeletion = handleDeleteTag(newCommentValue, positionToDelete)
		setNewComment(textAfterDeletion);
	}

	const getPositionToDelete = (newCommentValue: string, originalComment: string) => {
		for (let letterindex = 0; letterindex < newCommentValue.length; letterindex++) {
			const newCommentValueLetter = newCommentValue[letterindex];
			const originalCommentLetter = originalComment[letterindex]
			if (newCommentValueLetter !== originalCommentLetter) {
				return letterindex - 1
			}
		}
		return newCommentValue.length
	}

	const handleDeleteTag = (comment: string, index: number) => {
		for (const location of taggedText) {
			if (index >= location['start'] && index <= location['end']) {
				removeLocation(location)
				return comment.slice(0, location['start']) + comment.slice(location['end']);
			}
		}
		return comment;
	}

	const removeLocation = (locationToRemove: { start: number; end: number; name: string }) => {
		setTaggetText((prev) =>
			prev.filter(
				(item) =>
					item.start !== locationToRemove.start &&
					item.end !== locationToRemove.end &&
					item.name !== locationToRemove.name
			)
		);
		const studentDeleted = studentsOfGroup.find((student) => student.rut === locationToRemove.name)
		if (studentDeleted) {
			setTaggedStudents((prev) =>
				prev.filter(
					(item) =>
						item.rut !== studentDeleted.rut
				)
			)
		}
	}

	const handleAddNewCommentValue = (newCommentValue: string) => {
		const lastLetter = newCommentValue.slice(-1);
		const hasTag = newCommentValue.includes('@');
		console.log("LL ", lastLetter)
		setNewComment(newCommentValue);
		if (isTagging) {
			if (hasTag) {
				const valueAfterTag = newCommentValue.split('@').pop() || '';
				setTagText(valueAfterTag);
				const filtered = FilterStudentsForSearch(studentsOfGroup, valueAfterTag, taggedStudents)
				setFilteredStudents(filtered);
			} else {
				setIsTagging(false);
				setTagText('');
			}
		} else if (lastLetter === '@') {
			setIsTagging(true);
		}
	}

	const handleInternalClick = (student: StudentInterface) => {
		const lastTag = newComment.lastIndexOf('@');
		const commentWithoutTag = newComment.substring(0, lastTag);
		if (commentWithoutTag.trim() === '') {
			const currentTagInfo = { 'start': 0, 'end': student.rut.length, 'name': student.rut }
			setNewComment(`@[${student.name}](${student.rut}) `)
			setTaggetText([...taggedText, currentTagInfo])
		} else {
			const fixedNewComment = `${commentWithoutTag}@[${student.name}](${student.rut}) `
			const currentTagInfo = { 'start': lastTag, 'end': lastTag + student.rut.length, 'name': student.rut }
			setTaggetText([...taggedText, currentTagInfo])
			setNewComment(fixedNewComment)
		}
		setTaggedStudents([...taggedStudents, student])
		setIsTagging(false)
		setFilteredStudents([])

	}

	useEffect(() => {
		const group = groupsOfUserAsGroups.find(group => group.SK === noticeToSee.groups[0].id) ?? undefined
		setGroupOfNotice(group)
		setStudentsOfGroup(group ? group.students : [])
	}, [noticeToSee])

	useEffect(() => {
		const inputElement = inputStudentSearchRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			const style = {
				left: rect.left,
				bottom: window.innerHeight - rect.top + 1,
				width: rect.width + 20
			}
			setPopupStyle(style);
		}
	}, [tagText]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchStudentResultsRef.current &&
				!searchStudentResultsRef.current.contains(event.target as Node) &&
				inputStudentSearchRef.current &&
				!inputStudentSearchRef.current.contains(event.target as Node)
			) {
				setFilteredStudents([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchStudentResultsRef]);


	return (
		<>
			<div className="CommentShowMainDiv">
				{notFastComments.length === 0 ? (
					<h1>{noCommentMessage}</h1>
				) : (
					<div className="CommentShowSubMainDiv">
						{groupedComments.map((groupedComment: GroupedCommentInterface) => {
							if (user && groupedComment.user_id === user.SK) {
								return (
									groupedComment.comments.map((commentSummary: SingularCommentSummary) => (
										<div className="IndividualCommentOwnerMainDiv">
											<div className="IndividualCommentText IndividualCommentTextOwner">
												<img src={commentSummary.image} alt="profile"
													hidden={!commentSummary.image} />
												<h2>{commentSummary.comment}</h2>
												<h4>{commentSummary.date}</h4>
											</div>
										</div>
									))
								)
							} else {
								return (
									<div className="IndividualCommentMainDiv">
										<div className="IndividualCommentProfilePicture">
											<img src={BasicUserIcon} alt="profile" />
										</div>
										<div className="IndividualCommentTextArea">
											<h2>{groupedComment.user_id.split("@")[0]}</h2>
											{groupedComment.comments.map((commentSummary: SingularCommentSummary) => (
												<div className="IndividualCommentText IndividualCommentTextNotOwner">
													<img src={commentSummary.image} alt="profile"
														hidden={!commentSummary.image} />
													<h2>{commentSummary.comment}</h2>
													<h4>{commentSummary.date}</h4>
												</div>
											))}
										</div>
									</div>
								)
							}
						})}
					</div>
				)}
			</div>
			<div className="CommentInputMainDiv">
				{fileToUpload.length >= 1 && (
					<div className="CommentInputFileDiv">
						<h4>Archivo cargado: {fileName}</h4>
						<button type="button" onClick={() => setFileToUpload([])}>X</button>
					</div>
				)}
				{filteredStudents.length > 0 && popupStyle && isTagging && (
					<div
						className="StudentSearchResults"
						style={{ left: popupStyle.left, bottom: popupStyle.bottom, width: popupStyle.width }}
						ref={searchStudentResultsRef}
					>
						<ul className="StudentSearchList">
							{filteredStudents.map((student, index) => (
								<li key={index}
									className={`StudentSearchItem`}
									onClick={() => handleInternalClick(student)}>
									{student.name ? student.name : student.rut}
								</li>
							))}
						</ul>
					</div>
				)}
				<div className="CommentInputSubDiv">
					<input
						ref={inputStudentSearchRef}
						type="text"
						placeholder="Escribe tu comentario"
						value={FormatNewCommentForTagging(newComment)}
						onChange={handleCommentInputChange}
						onInput={(e) => {
							const input = e.target as HTMLInputElement;
							if (input.scrollWidth > input.clientWidth) {
								input.scrollLeft = input.scrollWidth;
							}
						}}
					/>
					<div className="CommentInputSubButtonDiv">
						<button onClick={() => createComment()}>
							Dejar comentario
						</button>
						<label htmlFor="file-upload" className="custom-file-upload">
							<img src={ClipIcon} alt="" />
						</label>
						<input
							id="file-upload"
							type="file"
							multiple
							accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.HEIF"
							onChange={handleFileChange}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
							style={{ display: 'none' }}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ViewCommentsOfNotice