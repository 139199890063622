import {
	CommentInterface, GroupedCommentInterface, SchedualInterface, SchoolInterface,
	SingularCommentSummary
} from "../Interfaces/Entities";
import { FormatNoticeInfoForTagging } from "./ReactHelpers";
import { TranslateCommentDate } from "./TranslateHelpers";

export function FormatSchoolsForSuperAdmin(currentSchools: SchoolInterface[]):
	(SchoolInterface | 'CreateSchool' | undefined)[][] {
	const rows: (SchoolInterface | 'CreateSchool' | undefined)[][] = [];
	const firstRow = ['CreateSchool', ...currentSchools.slice(0, 4)];
	rows.push(firstRow);

	let remainingSchools = currentSchools.slice(4);
	while (remainingSchools.length > 0) {
		const row = remainingSchools.slice(0, 5);
		while (row.length < 5) {
			row.push(undefined);
		}
		rows.push(row);
		remainingSchools = remainingSchools.slice(5);
	}

	return rows;
}

export function FormatCommentsForChat(comments: CommentInterface[]): GroupedCommentInterface[] {
	const groupedComments: GroupedCommentInterface[] = [];
	let currentUser = '';
	let currentUserName = '';
	let currentGroup: SingularCommentSummary[] = [];

	comments.forEach((comment) => {
		if (comment.user_id === currentUser) {
			currentGroup.push(
				{
					comment: FormatNoticeInfoForTagging(comment.comment),
					image: comment.image,
					date: TranslateCommentDate(comment)
				});
		} else {
			if (currentGroup.length > 0) {
				groupedComments.push({
					user_id: currentUser,
					user_name: currentUserName,
					comments: currentGroup
				});
			}
			currentUser = comment.user_id;
			currentUserName = comment.user_name;
			currentGroup = [{
				comment: FormatNoticeInfoForTagging(comment.comment),
				image: comment.image,
				date: TranslateCommentDate(comment)
			}];
		}
	});

	if (currentGroup.length > 0) {
		groupedComments.push({
			user_id: currentUser,
			user_name: currentUserName,
			comments: currentGroup
		});
	}

	return groupedComments
}

export function FormatDailyAgenda(agenda: SchedualInterface): string {
	return `${agenda["start"]}-${agenda["end"]}`
}


export function FormatCommentsForNewComment(noticeComments: CommentInterface[], school_id: string):
	Omit<CommentInterface, 'image'>[] {
	return noticeComments.map(({ image, ...rest }) => {
		// Note: Change this to be the same as backend in case of backend logic change
		const defaultValue = `${school_id}#NEWS_${rest.ts ? rest.ts : ''}_comment_picture.png`
		return image ? { ...rest, image: defaultValue } : rest;
	});
}

export function FormatNewCommentForTagging(text: string | undefined): string {
	if (!text) {
		return ""
	}
	const regex = /@\[(.+?)\]\((.+?)\)/g;
	return text.replace(regex, (_, codigo1, codigo2) => {
		return `@${codigo2}`;
	});
}

export function ReverseFormatNewCommentForTagging(processedText: string, mapping: Record<string, string>): string {
	if (!processedText) {
		return "";
	}
	const keys = Object.keys(mapping);
	let text = processedText
	keys.forEach(subkey => {
		const pattern = new RegExp(`@${subkey}`, 'g'); 
		text = text.replace(pattern, `@[${mapping[subkey]}](${subkey})`); 
	});
	return text
}