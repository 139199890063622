import React from "react"
import { ModalTypes } from "../Interfaces/ContextInterfaces/GeneralContext"
import { TranslateSelecterText } from "./TranslateHelpers"

export function FormatSelecterModalText(text: string, codeSection: string, currentModal:
	ModalTypes['type']): JSX.Element | string {
	const translatedModal = TranslateSelecterText(currentModal)
	if (codeSection === translatedModal) {
		return <strong>{text}</strong>
	} else {
		return text
	}
}

export function FormatNoticeInfoForTagging(text: string | undefined): JSX.Element {
	if (!text) {
		return <></>
	}
	const partes = text.split(/(@\[[^\]]+\]\([^\)]+\))/g);

	return (
		<>
			{partes.map((parte, index) => {
				const match = parte.match(/@\[(.+?)\]\((.+?)\)/);
				if (match) {
					const [, , codigo2] = match;
					return <span key={index} className="TagTextClassName">@{codigo2}</span>;
				}
				return <span key={index}>{parte}</span>;
			})}
		</>
	);
}